import { getPathWithoutFilter } from './get-path-without-filter';
import { FilterParamsResult, chpu } from './index';

export const encodeUrl = (
    path: string,
    params: FilterParamsResult,
    options?: Partial<{ ignoredKeys: string[]; rangeKeys: string[] }>,
) => {
    const splittedPath = getPathWithoutFilter(path).split('?');
    const searchParams = new URLSearchParams(splittedPath[1]).toString();

    return (
        `${splittedPath[0]
            .replace(/\/filter\/.*\/apply/, '')
            .replace(path.match(/\/filter\/.*\/apply/) ? '' : /\/filter\/.*/, '')
            .replace(/\/$/, '')}${chpu.encodeParamsToUrl(params, options) || '/'}`.replace('/filter//apply/', '/') +
        (searchParams ? `?${searchParams}` : '')
    );
};
