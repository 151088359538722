import LogoSVG from '@public/icons/logo.svg';
import MenuLinesSVG from '@public/icons/menu-lines.svg';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFullLogoStateValue } from '@/shared/atoms/full-logo';
import { useHeaderThemeValue, useSetHeaderTheme } from '@/shared/atoms/header-theme';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import { MENU_POPUP_NAME } from '@/shared/components/popups/MenuPopup/MenuPopup';
import FeedbackBtn from '@/shared/components/shared/FeedbackBtn';
import HeaderDropdown from '@/shared/components/shared/HeaderDropdown';
import Link from '@/shared/components/shared/Link';
import { Theme } from '@/shared/lib/types';
import { SEARCH_POPUP_NAME } from '../../popups/SearchPopup/SearchPopup';

const themes: Theme[] = ['light', 'dark'];

const Header = () => {
    const defaultTheme: Theme = 'light';
    const theme = useHeaderThemeValue();
    const setHeaderTheme = useSetHeaderTheme();
    const { openPopup } = useSetOpenedPopupsState();

    const isFullLogo = useFullLogoStateValue();
    const centerNav = [
        {
            name: 'Программа',
            slug: '/program',
        },
        {
            name: 'Выставка',
            slug: '/expo',
        },
        {
            name: 'События',
            links: [
                { name: 'Технологические дни', slug: '/events/technological' },
                { name: 'HR-Саммит и Премия', slug: '/events/summit' },
            ],
        },
        {
            name: 'Варианты участия',
            links: [
                { name: 'Участникам', slug: '/participation/participants' },
                { name: 'Партнёрам', slug: '/participation/partners' },
                { name: 'Посетителям', slug: '/participation/visitors' },
            ],
        },
        {
            name: 'Новости',
            slug: '/news',
        },
        {
            name: 'Контакты',
            slug: '/contacts',
        },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const target = entry.target as HTMLElement;

                    if (entry.isIntersecting) {
                        const theme = target.dataset.theme as Theme;
                        setHeaderTheme(themes.includes(theme) ? theme : defaultTheme);
                    }
                });
            },
            { rootMargin: '-6% 0% -93.5% 0%', threshold: [0, 1] },
        );

        let timeout: NodeJS.Timeout;

        const onNewPageReady = () => {
            observer.disconnect();
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                Array.from(document.querySelectorAll<HTMLElement>('.js-header-theme-trigger')).forEach((el, i) => {
                    observer.observe(el);
                });
            }, 100);
        };

        onNewPageReady();
        document.addEventListener('new-page-ready', onNewPageReady);

        return () => {
            clearTimeout(timeout);
            document.removeEventListener('new-page-ready', onNewPageReady);
            observer.disconnect();
        };
    }, [setHeaderTheme]);

    return (
        <header className={`header header-theme--${theme}`}>
            <div className="wrapper header-wrapper">
                <div className="header-left">
                    <Link href={'/'} className="header-logo" aria-label="Главная">
                        <LogoSVG className={classNames('header-logo-svg', { 'full-logo': isFullLogo })} />
                    </Link>
                    <button
                        className="header-menu header-btn blur-bg middle-out"
                        onClick={() => {
                            openPopup(MENU_POPUP_NAME);
                        }}
                    >
                        <div className="menu-btn-text text-xs">Меню</div>
                        <MenuLinesSVG className="header-lines-svg" />
                    </button>
                </div>
                <div className="header-center blur-bg">
                    {centerNav.map((link, i) =>
                        link.links ? (
                            <HeaderDropdown links={link.links} btnText={link.name} key={i} />
                        ) : (
                            <Link href={link.slug} key={i} prefetch className="header-btn middle-out text-xs">
                                {link.name}
                            </Link>
                        ),
                    )}
                </div>

                <div className="header-search">
                    <button
                        className="header-btn blur-bg middle-out text-xs"
                        onClick={() => {
                            openPopup(SEARCH_POPUP_NAME);
                        }}
                    >
                        Поиск
                    </button>
                </div>
                <div className="header-right">
                    <Link
                        href="https://lk.oilgasforum.ru/auth/"
                        target="_self"
                        className="header-btn blur-bg middle-out text-xs"
                    >
                        <span>Личный кабинет</span>
                    </Link>

                    <Link
                        target="_self"
                        href="/registration/"
                        className="header-btn blur-bg middle-out text-xs primary-header-btn"
                    >
                        Принять участие
                    </Link>
                </div>
            </div>
            <FeedbackBtn theme={theme} />
        </header>
    );
};

export default Header;
