import classNames from 'classnames';
import { forwardRef } from 'react';
import { Soc } from '@/entities';

export type SocListProps = Soc[];

type Props = React.HTMLAttributes<HTMLUListElement> & {
    data: SocListProps;
};

const SocList = forwardRef<HTMLUListElement, Props>(({ data, ...props }, ref) => {
    return data.length > 0 ? (
        <ul {...props} ref={ref} className={classNames('list-unstyled soc-list', props.className)}>
            {data.map((soc, i) => (
                <li key={i}>
                    <a
                        href={soc.href}
                        className="soc-list-item"
                        target="_blank"
                        rel="noreferrer"
                        aria-label={soc.name || 'Перейти в соцсеть'}
                        dangerouslySetInnerHTML={{ __html: soc.icon || '' }}
                    ></a>
                </li>
            ))}
        </ul>
    ) : null;
});

SocList.displayName = 'SocList';

export default SocList;
