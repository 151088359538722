import ChipsaSVG from '@public/icons/chipsa.svg';
import Icon1 from '@public/icons/footer-icon-1.svg?url';
import Icon2 from '@public/icons/footer-icon-2.svg?url';
import { useRouter } from 'next/router';
import { Soc } from '@/entities';
import { Document } from '@/entities/document';
import Button from '@/shared/components/shared/Button';
import FooterInput from '@/shared/components/shared/FooterInput';
import { isPdf } from '@/shared/hooks/use-form-sender/validator/utils';
import Link from '../../shared/Link';
import SocList from '../../shared/SocList';
import Typografed from '../../shared/Typografed';

export type FooterData = {
    formText: string;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
    socialLinks?: Soc[];
    copyrightYear: number;
    privacy: string;
    oferta?: Document | null;
    support: string;
};

interface Props {
    footerData: FooterData;
}

const Footer = ({ footerData }: Props) => {
    const router = useRouter();
    const { asPath } = router;
    const theme = asPath === '/' ? 'light' : 'dark';

    return (
        <footer className="footer js-header-theme-trigger" data-bg="#082E74" data-theme={theme}>
            <div className="footer-inner wrapper">
                <div className="footer-top">
                    <div className="footer-top-left">
                        <div className="footer-news">
                            {footerData.formText && (
                                <div className="footer-news-text text-l">
                                    <Typografed>{footerData.formText}</Typografed>
                                </div>
                            )}
                            <FooterInput />
                        </div>
                    </div>
                    <div className="footer-top-right">
                        <div className="footer-top-right-inner">
                            {footerData.address && (
                                <div className="footer-address-wrapper">
                                    <div className="footer-item footer-address">
                                        <div className="footer-item-top text-s">Адрес:</div>
                                        <div className="footer-item-bottom text-s">
                                            <Typografed>{footerData.address}</Typografed>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="footer-item--map">
                                <Button
                                    tag={Link}
                                    href={'/contacts/#contatsMap'}
                                    variant="outline-light"
                                    className="footer-item--map-inner text-xs"
                                >
                                    Показать на карте
                                </Button>
                            </div>
                            <div className="footer-contacts">
                                {footerData.phone && (
                                    <div className="footer-item">
                                        <div className="footer-item-top text-s">Телефон:</div>
                                        <a href={`tel:${footerData.phone}`} className="footer-item-bottom text-s link">
                                            {footerData.phone}
                                        </a>
                                    </div>
                                )}
                                {footerData.email && (
                                    <div className="footer-item">
                                        <div className="footer-item-top text-s">E-mail:</div>
                                        <a
                                            href={`mailto:${footerData.email}`}
                                            className="footer-item-bottom text-s link"
                                        >
                                            {footerData.email}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        {footerData.socialLinks && footerData.socialLinks.length > 0 && (
                            <SocList data={footerData.socialLinks} className="footer-soc-list" />
                        )}
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom-top">
                        <div className="footer-partners text-xs">
                            <div className="footer-partner">
                                <picture>
                                    <img
                                        src={Icon1}
                                        alt="Правительство Российской Федерации"
                                        className="img-fluid"
                                        loading="lazy"
                                    />
                                </picture>
                                <div className="footer-partner-text">Правительство Российской Федерации</div>
                            </div>
                            <div className="footer-partner">
                                <picture>
                                    <img
                                        src={Icon2}
                                        alt="Правительство Тюменской области"
                                        className="img-fluid"
                                        loading="lazy"
                                    />
                                </picture>
                                <div className="footer-partner-text">Правительство Тюменской области</div>
                            </div>
                        </div>
                        <div className="footer-support">
                            <div className="footer-item text-s">
                                Остались вопросы?
                                <br />
                                Напишите в службу поддержки
                            </div>
                            {footerData.support && (
                                <div className="footer-item footer-support-link">
                                    <Button
                                        tag={Link}
                                        href={footerData.support}
                                        variant="outline-light"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Служба поддержки
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="footer-bottom-bottom">
                        <div className="footer-bottom-left">
                            <div className="text-xs footer-copyright">
                                ©{footerData.copyrightYear} Промышленно-энергетический форум TNF
                            </div>
                            <Link
                                target="_blank"
                                rel="noreferrer"
                                href="https://chipsa.ru/?utm_source=tnf&utm_medium=works&utm_campaign=all"
                                className="developer text-xs"
                            >
                                <ChipsaSVG className="developer-icon" />
                                <div className="link developer-text">Сделано в Chipsa</div>
                            </Link>
                        </div>
                        <div className="footer-bottom-right">
                            {footerData.privacy && (
                                <div className="footer-item footer-bottom-right-link-wrapper">
                                    <Link
                                        href={footerData.privacy}
                                        target="_blank"
                                        rel="noreferrer nofollow"
                                        className="text-xs link"
                                    >
                                        Политика конфиденциальности
                                    </Link>
                                </div>
                            )}
                            {footerData.oferta && footerData.oferta.href && (
                                <div className="footer-item footer-bottom-right-link-wrapper">
                                    <Link
                                        className="text-xs link"
                                        target={isPdf(footerData.oferta.href) ? '_blank' : undefined}
                                        rel={isPdf(footerData.oferta.href) ? 'noreferrer' : undefined}
                                        download={isPdf(footerData.oferta.href) ? undefined : true}
                                        href={footerData.oferta.href}
                                    >
                                        {footerData.oferta.name}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
