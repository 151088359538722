import { create } from 'zustand';
import { Theme } from '@/shared/lib/types';

export const useHeaderThemeStore = create<{
    headerTheme: Theme;
    setHeaderTheme: (theme: Theme) => void;
}>((set) => ({
    headerTheme: 'light',
    setHeaderTheme: (theme) => set(() => ({ headerTheme: theme })),
}));

export const useHeaderThemeValue = () => useHeaderThemeStore((state) => state.headerTheme);
export const useSetHeaderTheme = () => useHeaderThemeStore((state) => state.setHeaderTheme);
