import { create } from 'zustand';

type MediaQueryDeviceState =
    | 'horizontal-mobile'
    | 'vertical-mobile'
    | 'horizontal-tablet'
    | 'vertical-tablet'
    | 'desktop';

interface State {
    mediaQueryDevice: MediaQueryDeviceState;
    setMediaQueryDevice: (device: MediaQueryDeviceState) => void;
}

export const useMediaQueryDeviceStore = create<State>((set) => ({
    mediaQueryDevice: 'desktop',
    setMediaQueryDevice: (device) => set({ mediaQueryDevice: device }),
}));

export const useMediaQueryDeviceStateValue = () => useMediaQueryDeviceStore((state) => state.mediaQueryDevice);

export const useSetMediaQueryDeviceState = () => useMediaQueryDeviceStore((state) => state.setMediaQueryDevice);
