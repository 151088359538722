/**
 * Image component.
 *
 * It replicates 'next/image' with additional features.
 */
import classNames from 'classnames';
import { ImageProps, default as NextImage } from 'next/image';
import { forwardRef } from 'react';

type Props = ImageProps;

const Image = forwardRef<HTMLImageElement, Props>(
    ({ children, priority, unoptimized, fill, loader, quality, placeholder, blurDataURL, onLoad, ...props }, ref) => {
        const isSvgImage = typeof props.src === 'string' && props.src.endsWith('.svg');

        return isSvgImage ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
                {...props}
                ref={ref}
                src={typeof props.src === 'string' ? props.src : ''}
                alt={props.alt || ''}
                loading={priority ? 'eager' : 'lazy'}
                fetchPriority={priority ? 'high' : 'auto'}
                decoding="async"
                className={classNames(props.className, { responsive__item: fill })}
            />
        ) : (
            <NextImage
                {...props}
                ref={ref}
                priority={priority}
                unoptimized={unoptimized || isSvgImage}
                fill={fill}
                loader={loader}
                quality={quality}
                placeholder={placeholder}
                blurDataURL={blurDataURL}
                onLoad={onLoad}
            >
                {children}
            </NextImage>
        );
    },
);

Image.displayName = 'Image';

export default Image;
