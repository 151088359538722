import CrossSVG from '@public/icons/cross.svg';
import FeedBackSVG from '@public/icons/feedback-btn.svg';
import classNames from 'classnames';
import { m as motion } from 'framer-motion';
import React, { useState } from 'react';
import { useSetFeedbackBtnOpenedLinks } from '@/shared/atoms/feedback-links';
import { Theme } from '@/shared/lib/types';
import Button from '../Button';

interface Props {
    theme: Theme;
}

function FeedbackBtn({ theme }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const setOpenedLinks = useSetFeedbackBtnOpenedLinks();

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setOpenedLinks(!isOpen);
    };

    return (
        <div className={`feedback-btn-wrapper feedback-theme--${theme}`}>
            <motion.button
                className={classNames('feedback-btn', { 'is-active': isOpen })}
                onClick={toggleOpen}
                aria-label="Открыть форму обратной связи"
            >
                <motion.div
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : 5 }}
                    transition={{ duration: 0.3 }}
                    key="cross-svg"
                >
                    {isOpen && <CrossSVG />}
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: !isOpen ? 1 : 0, y: !isOpen ? 0 : 20 }}
                    transition={{ duration: 0.3 }}
                    key="feedback-svg"
                >
                    {!isOpen && <FeedBackSVG />}
                </motion.div>
            </motion.button>

            <motion.div
                className="feedback-links"
                initial={{ opacity: 0, y: 25, pointerEvents: 'none' }}
                animate={{
                    opacity: isOpen ? 1 : 0,
                    y: isOpen ? 0 : 25,
                    pointerEvents: isOpen ? 'all' : 'none',
                }}
                transition={{ duration: 0.3 }}
            >
                <Button
                    tag="a"
                    href={'https://t.me/TnfChatBot'}
                    target="_blank"
                    rel="noreferrer nofollow"
                    className="feedback-link-btn"
                    variant="outline-light"
                >
                    Служба поддержки TNF
                </Button>
                {/* <Button
                    tag="a"
                    href={'https://t.me/oilgasforum_TNF'}
                    target="_blank"
                    rel="noreferrer nofollow"
                    className="feedback-link-btn"
                    variant="outline-light"
                >
                    TNF Forum
                </Button>
                <Button
                    tag="a"
                    href={'https://t.me/TNFLIVE'}
                    target="_blank"
                    rel="noreferrer nofollow"
                    className="feedback-link-btn"
                    variant="outline-light"
                >
                    TNF Live
                </Button> */}
            </motion.div>
        </div>
    );
}

export default FeedbackBtn;
