import { useTypograf } from '@/shared/hooks/use-typograf';

type Props = {
    children: string;
};

const Typografed = ({ children }: Props) => {
    const tpValue = useTypograf(children);

    return tpValue;
};

export default Typografed;
