import classNames from 'classnames';
import { forwardRef } from 'react';
import Typografed from '../Typografed';

type Props = React.HTMLAttributes<HTMLElement> & {
    children?: string;
    error?: unknown;
    resetError?: () => void;
};

const ErrorMessage = forwardRef<HTMLDivElement, Props>(({ children, error, resetError, ...props }, ref) => {
    return (
        <div {...props} ref={ref} className={classNames('error-message', props.className)}>
            <div className="error-message__title text-h-m">
                <Typografed>{children || 'Произошла ошибка'}</Typografed>
            </div>
            {error instanceof Error && <div className="error-message__error-message text-m">{error.message}</div>}
            {typeof resetError === 'function' && (
                <button className="link link--underlined text-xxs error-message__reset" onClick={resetError}>
                    <Typografed>Нажмите здесь, чтобы сбросить ошибку</Typografed>
                </button>
            )}
        </div>
    );
});

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
