import { toFormData } from '../lib/utils/forms';
import { axiosAPI } from './axios-instance';
import { ApiResponse } from './lib/types';

export type ApiSubscribeRequest = {
    email: string;
};

export type ApiSubscribeResponse = ApiResponse<{
    message: string;
}>;

export type AppSubscribeResponse = ApiResponse<{
    message: string;
}>;

const transformApiResponse = (response: ApiSubscribeResponse): AppSubscribeResponse => {
    return response;
};

export const postSubscribe = (body: ApiSubscribeRequest): Promise<AppSubscribeResponse> =>
    axiosAPI
        .post<ApiSubscribeResponse>('/v1/feedback/subscribe', toFormData(body))
        .then((res) => transformApiResponse(res.data));
