export const DICT: Record<string, Record<string, string>> = {
    SOMETHING_WENT_WRONG: {
        ru: 'Что-то пошло не так',
        en: 'Something went wrong',
    },
    INTERNAL_SERVER_ERROR: {
        ru: 'Внутренняя ошибка сервера',
        en: 'Internal server error',
    },
    COOKIE_TEXT: {
        ru: 'Продолжая использовать сайт, вы соглашаетесь со сбором файлов cookie для работы сайта и его улучшения',
        en: 'By continuing to use the site, you agree to the collection of cookies for the operation of the site and its improvement',
    },
    ACCOUNT: {
        ru: 'Личный кабинет',
        en: 'Account',
    },
    SEARCH: {
        ru: 'Поиск',
        en: 'Search',
    },
    SEARCH_FIND: {
        ru: 'Найти',
        en: 'Find',
    },
    CLOSE: {
        ru: 'Закрыть',
        en: 'Close',
    },
    SEARCH_INPUT_TEXT: {
        ru: 'Что вас интересует?',
        en: 'What are you interested in?',
    },
    MAIN: {
        ru: 'Главная',
        en: 'Main',
    },
    ALL: {
        ru: 'Все',
        en: 'All',
    },
    NOT_FOUND: {
        ru: 'К сожалению, по вашему запросу ничего не найдено',
        en: 'Unfortunately, nothing was found for your request',
    },
};
