import { create } from 'zustand';

export const useScrollLockStore = create<{
    scrollLock: boolean;
    setScrollLock: (value: boolean) => void;
}>((set) => ({
    scrollLock: false,
    setScrollLock: (value) => set(() => ({ scrollLock: value })),
}));

export const useScrollLockStateValue = () => useScrollLockStore((state) => state.scrollLock);
export const useSetScrollLockState = () => useScrollLockStore((state) => state.setScrollLock);
