import classNames from 'classnames';
import {
    ButtonHTMLAttributes,
    Dispatch,
    HTMLAttributes,
    SetStateAction,
    createContext,
    forwardRef,
    useContext,
    useRef,
    useState,
} from 'react';
import { useOnOutsideClickAction } from '@/shared/hooks/use-on-outside-click-action';
import { Props as ButtonProps } from '../Button/Button';

interface Props extends HTMLAttributes<HTMLElement> {}

const DropdownContext = createContext<{ opened: boolean; setOpened: Dispatch<SetStateAction<boolean>> }>({
    opened: false,
    setOpened: () => {},
});

export const useDropdownContext = () => useContext(DropdownContext);

type TogglerProps = ButtonHTMLAttributes<HTMLButtonElement> &
    Partial<ButtonProps> & {
        keepOpen?: boolean;
    };

const Toggler = ({ tag = 'button', ...props }: TogglerProps) => {
    const { opened, setOpened } = useContext(DropdownContext);
    const Component = tag as any;

    return (
        <Component
            {...props}
            type={tag === 'button' ? 'button' : undefined}
            className={classNames('dropdown-btn', props.className)}
            onClick={(event: any) => {
                setOpened(!opened);
                props.onClick?.(event);
            }}
            aria-haspopup="true"
        ></Component>
    );
};

const Picker = ({ tag = 'button', keepOpen, ...props }: TogglerProps) => {
    const { setOpened } = useContext(DropdownContext);
    const Component = tag as any;

    return (
        <Component
            {...props}
            type={tag === 'button' ? 'button' : undefined}
            className={classNames('dropdown-picker-btn', props.className)}
            onClick={(event: any) => {
                if (!keepOpen) setOpened(false);
                props.onClick?.(event);
            }}
        ></Component>
    );
};

const Content = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLElement> & {
        position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'bottom-center';
    }
>(({ children, position = 'bottom-left', ...props }, ref) => {
    return (
        <div {...props} ref={ref} className={classNames('dropdown-content', props.className, position)}>
            <div className="dropdown-content-inner">{children}</div>
        </div>
    );
});

Content.displayName = 'Content';

const Dropdown = ({ children, ...props }: Props) => {
    const el = useRef<HTMLDivElement>(null);

    const [opened, setOpened] = useState(false);
    useOnOutsideClickAction(el, () => setOpened(false));

    return (
        <DropdownContext.Provider value={{ opened, setOpened }}>
            <div
                {...props}
                ref={el}
                className={classNames('dropdown js-dropdown', props.className, { 'dropdown--opened': opened })}
            >
                {children}
            </div>
        </DropdownContext.Provider>
    );
};

Dropdown.Toggler = Toggler;
Dropdown.Content = Content;
Dropdown.Picker = Picker;

export default Dropdown;
