import { create } from 'zustand';

export const useFeedbackBtnOpenedLinksStore = create<{
    feedbackBtnOpenedLinks: boolean;
    setFeedbackBtnOpenedLinks: (value: boolean) => void;
}>((set) => ({
    feedbackBtnOpenedLinks: false,
    setFeedbackBtnOpenedLinks: (value) => set({ feedbackBtnOpenedLinks: value }),
}));

export const useFeedbackBtnOpenedLinksValue = () =>
    useFeedbackBtnOpenedLinksStore((state) => state.feedbackBtnOpenedLinks);
export const useSetFeedbackBtnOpenedLinks = () =>
    useFeedbackBtnOpenedLinksStore((state) => state.setFeedbackBtnOpenedLinks);
