import { create } from 'zustand';

export type PageTransitionName = 'default' | 'instant' | 'leaveMenu' | 'leaveNewsToNews';

export const DEFAULT_MODE = 'wait';
export const DEFAULT_NAME = 'default';

const usePageTransitionStateStore = create<{
    currentTransition: {
        mode: 'wait' | 'sync' | 'popLayout';
        name: PageTransitionName;
        targetElement?: Element | null;
    };
    setCurrentTransition: (value: { mode: 'wait' | 'sync' | 'popLayout'; name: PageTransitionName }) => void;
    isPageTransitioning: boolean;
    setIsPageTransitioning: (value: boolean) => void;
}>((set) => ({
    currentTransition: {
        mode: DEFAULT_MODE,
        name: DEFAULT_NAME,
    },
    setCurrentTransition: (value) => set(() => ({ currentTransition: value })),
    isPageTransitioning: false,
    setIsPageTransitioning: (value) => set(() => ({ isPageTransitioning: value })),
}));

export const usePageTransitionValue = () => usePageTransitionStateStore((state) => state.currentTransition);
export const useSetPageTransitionState = () => usePageTransitionStateStore((state) => state.setCurrentTransition);

export const useIsPageTransitioningValue = () => usePageTransitionStateStore((state) => state.isPageTransitioning);
export const useSetIsPageTransitioningState = () =>
    usePageTransitionStateStore((state) => state.setIsPageTransitioning);
