/**
 * Все провайдеры подключаются в этом файле.
 */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LazyMotion } from 'framer-motion';
import { ReactNode } from 'react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    },
});

const Providers = ({ children }: { children: ReactNode }) => {
    return (
        <LazyMotion features={() => import('@/framer-motion-features').then((m) => m.default)} strict>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </LazyMotion>
    );
};

export default Providers;
