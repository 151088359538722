'use client';

import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const YM_ID = 38452385;

const Metrics = () => {
    const { pathname } = useRouter();
    const searchParams = useSearchParams();

    useEffect(() => {
        (window as any).ym?.(YM_ID, 'hit', window.location.href);
    }, [pathname, searchParams]);

    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: `
                    <!-- Yandex.Metrika counter -->
                    <script type="text/javascript"> 
                        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                        
                        ym(${YM_ID}, "init", { defer:true, clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true });
                    </script>
                    <noscript><div><img src="https://mc.yandex.ru/watch/${YM_ID}" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
                    <!-- /Yandex.Metrika counter -->
                    `,
                }}
            ></div>
        </>
    );
};

export default Metrics;
