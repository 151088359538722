import ArrSVG from '@public/icons/btn-arr.svg';
import classNames from 'classnames';
import { LinkProps } from 'next/link';
import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import Link from '../Link';
import Spinner from '../Spinner';

export type Props = ButtonHTMLAttributes<HTMLButtonElement> &
    Partial<LinkProps> & {
        /**
         * Какой тег рендерить
         */
        tag?: keyof JSX.IntrinsicElements | typeof Link;
        /**
         * Геометрия кнопки
         */
        geometryVariant?: 'default' | 'wide';
        /**
         * Цветовая тема кнопки
         */
        variant?:
            | 'outline-dark'
            | 'outline-light'
            | 'primary'
            | 'dark-filled'
            | 'primary-filled'
            | 'outline-primary'
            | 'blue'
            | 'dark-blue'
            | 'white'
            | 'grey-filled';
        target?: string;
        asyncActionActive?: boolean;
        icon?: ReactNode;
    };

const Button = forwardRef<HTMLButtonElement, Props>(
    (
        {
            children,
            variant = 'primary',
            tag = 'button',
            geometryVariant = 'default',
            asyncActionActive = false,
            ...props
        },
        ref,
    ) => {
        const Component = tag;

        return (
            <Component
                {...props}
                ref={ref}
                className={classNames('btn', `btn-${variant}`, `btn-geometry-${variant}`, props.className, {
                    'is-async-action-active': asyncActionActive,
                })}
            >
                {!['primary', 'white', 'blue', 'grey-filled'].includes(variant) && <ArrSVG className="btn__arrow" />}
                <span className="btn__text">{children}</span>
                {asyncActionActive && <Spinner />}
            </Component>
        );
    },
);

Button.displayName = 'Button';

export default Button;
