import ArrSVG from '@public/icons/btn-arr.svg';
import CloseSVG from '@public/icons/close-btn.svg';
import MenuLogoSVG from '@public/icons/opened-menu-logo.svg?url';
import classNames from 'classnames';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Soc } from '@/entities';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import { useOpenedPopupsValue, useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import { useSetPageTransitionState } from '@/shared/atoms/page-transition';
import { formatPhoneHref } from '@/shared/lib/utils/strings';
import Image from '../../shared/Image';
import Link from '../../shared/Link';
import SocList from '../../shared/SocList';
import { SEARCH_POPUP_NAME } from '../SearchPopup/SearchPopup';

type Props = {
    socList: Soc[];
    email: string | null;
    phone: string | null;
};

type MenuPopupLink = {
    name: string;
    slug: string;
    newWindow?: boolean;
};

const root = typeof window !== 'undefined' ? document.querySelector('#modal-root') : null;

const menuData = {
    links: [
        { name: 'Программа', slug: '/program/' },
        { name: 'Выставка', slug: '/expo/' },
        {
            name: 'События',
            links: [
                { name: 'Технологические дни', slug: '/events/technological/' },
                { name: 'HR-Саммит и Премия', slug: '/events/summit/' },
            ],
        },
        {
            name: 'Варианты участия',
            links: [
                { name: 'Участниками', slug: '/participation/participants/' },
                { name: 'Партнерам', slug: '/participation/partners/' },
                { name: 'Посетителям', slug: '/participation/visitors/' },
            ],
        },
        {
            name: 'Услуги',
            links: [
                { name: 'Бронирование гостиниц', slug: '/services/hotels/' },
                { name: 'Аренда транспорта', slug: '/services/car-rent/' },
                { name: 'Аккредитация транспорта', slug: '/services/car-accreditation/' },
                { name: 'Шаттлы', slug: '/services/shuttles/' },
                { name: 'Зоны питания', slug: '/services/food-areas/' },
            ],
        },
        { name: 'Фотобанк', slug: 'https://oilgasforum2024.tassphoto.com/', newWindow: true, index: false },
        { name: 'Календарь мероприятий', slug: '/community/' },
        { name: 'Новости', slug: '/news/' },
        { name: 'Регистрация', slug: '/registration/' },
        { name: 'Контакты', slug: '/contacts/' },
    ],
    phone: '8 (800) 350-26-37',
    email: 'welcome@oilgasforum.ru',
};

export const MENU_POPUP_NAME = 'menu-popup';

const MenuPopup = ({ socList, phone, email }: Props) => {
    const openedPopups = useOpenedPopupsValue();
    const setLeaveTransition = useSetPageTransitionState();
    const { closePopup, openPopup } = useSetOpenedPopupsState();
    const [hoveredLink, setHoveredLink] = useState<MenuPopupLink[]>([]);
    const [mobileBlueOpened, setMobileBlueOpened] = useState(true);
    const isMenuPopupOpen = openedPopups.includes(MENU_POPUP_NAME);
    const device = useMediaQueryDeviceStateValue();
    const isMobile = device.includes('mobile');

    const handleMouseEnter = (link: any) => {
        if (link.links) {
            setHoveredLink(link.links);
        } else {
            setHoveredLink([]);
        }
    };

    const Component = (
        <div
            className={classNames('menu-popup js-menu-popup popup', {
                'popup--opened': openedPopups.includes(MENU_POPUP_NAME),
            })}
            data-popup-preset="slide-left"
        >
            <div
                className="popup-overlay"
                onClick={() => {
                    closePopup(MENU_POPUP_NAME);
                    setHoveredLink([]);
                }}
            ></div>
            <div className="popup-container">
                <div className="menu-popup-top">
                    <Link href={'/'}>
                        <Image src={MenuLogoSVG} alt="logo" width={80} height={80} className="menu-popup-logo" />
                    </Link>
                    <button
                        className="header-btn middle-out text-xs"
                        onClick={() => {
                            openPopup(SEARCH_POPUP_NAME);
                        }}
                    >
                        Поиск
                    </button>
                    <button
                        className="menu-popup-close-btn"
                        onClick={() => {
                            closePopup(MENU_POPUP_NAME);
                            setHoveredLink([]);
                        }}
                    >
                        <CloseSVG />
                    </button>
                </div>
                <div className="menu-popup-mobile-buttons">
                    <Link
                        href="https://lk.oilgasforum.ru/auth/"
                        target="_blank"
                        rel="noreferrer nofollow"
                        className="header-btn blur-bg middle-out text-xs"
                    >
                        Личный кабинет
                    </Link>
                    <Link href="/registration/" className="header-btn middle-out text-xs primary-header-btn">
                        Принять участие
                    </Link>
                </div>
                <div className="menu-popup-items">
                    {menuData.links.map((item, i) =>
                        item.slug ? (
                            <Link
                                href={item.slug}
                                key={i}
                                className="menu-popup-link text-l"
                                target={item.newWindow ? '_blank' : undefined}
                                rel={
                                    item.newWindow
                                        ? ['noreferrer', item.index === false ? 'nofollow' : '']
                                              .filter(Boolean)
                                              .join(' ')
                                        : undefined
                                }
                                onClick={() => {
                                    if (!isMobile) {
                                        setLeaveTransition({
                                            mode: 'wait',
                                            name: 'leaveMenu',
                                        });
                                    }
                                }}
                                onMouseEnter={() => {
                                    handleMouseEnter(item);
                                }}
                            >
                                {item.name}
                            </Link>
                        ) : (
                            <button
                                key={i}
                                className="menu-popup-link text-l"
                                onClick={() => {
                                    handleMouseEnter(item);
                                    setMobileBlueOpened(true);
                                }}
                                onMouseEnter={() => {
                                    handleMouseEnter(item);
                                }}
                            >
                                {item.name}
                            </button>
                        ),
                    )}
                </div>
                <div className="menu-popup-bottom">
                    <div className="menu-popup-bottom-text">
                        {phone && (
                            <div className="menu-text-item">
                                <div className="menu-text-item-top text-s">Телефон:</div>
                                <div className="menu-text-item-bottom text-s">
                                    <a href={`tel:${formatPhoneHref(phone)}`} className="link">
                                        {phone}
                                    </a>
                                </div>
                            </div>
                        )}
                        {email && (
                            <div className="menu-text-item">
                                <div className="menu-text-item-top text-s">E-mail:</div>
                                <div className="menu-text-item-bottom text-s">
                                    <a href={`mailto:${email}`} className="link">
                                        {email}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                    {socList.length > 0 && <SocList data={socList} className="menu-popup-soc-list" />}
                </div>
            </div>
            <>
                <div
                    className={`menu-popup-blue-section js-menu-popup-blue-bg ${hoveredLink?.length > 0 && isMenuPopupOpen && mobileBlueOpened ? 'menu-popup-opened' : 'menu-popup-closed'}`}
                >
                    <button
                        className="menu-back-blue-btn"
                        onClick={() => {
                            setMobileBlueOpened(false);
                        }}
                    >
                        <ArrSVG />
                    </button>
                    <div className="menu-popup-blue-section-links js-menu-popup-blue-links">
                        {hoveredLink.map((link, i) => (
                            <Link
                                href={link.slug}
                                key={i}
                                className="menu-popup-link text-l"
                                onClick={() => {
                                    if (!isMobile) {
                                        setLeaveTransition({
                                            mode: 'wait',
                                            name: 'leaveMenu',
                                        });
                                    }
                                    setTimeout(() => {
                                        setHoveredLink([]);
                                    }, 1000);
                                }}
                            >
                                {link.name}
                            </Link>
                        ))}
                    </div>
                </div>
                <div
                    className={`menu-popup-white-bg js-menu-popup-white-bg ${isMenuPopupOpen ? 'menu-popup-opened' : 'menu-popup-closed'}`}
                ></div>
            </>
        </div>
    );

    return root ? createPortal(Component, root) : Component;
};

export default MenuPopup;
