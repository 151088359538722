import { useEffect } from 'react';
import { useSetMediaQueryDeviceState } from '@/shared/atoms/media-query-device';
import { useOpenedPopupsValue } from '@/shared/atoms/opened-popups';
import { useSetPageDataState } from '@/shared/atoms/page-data';
import { useScrollLockStateValue } from '@/shared/atoms/scroll-lock';
import { lockBodyScroll, unlockBodyScroll } from '@/shared/lib/utils/dom';

const AppInits = () => {
    const setMediaQueryDeviceState = useSetMediaQueryDeviceState();
    const scrollLocked = useScrollLockStateValue();
    const openedPopups = useOpenedPopupsValue();
    const setPageData = useSetPageDataState();

    /**
     * Стейт глобальных данных на странице
     */
    useEffect(() => {
        const onNewPageReady = () => {
            setPageData(
                typeof window !== 'undefined'
                    ? document.querySelector('.js-page-data-json')
                        ? JSON.parse(document.querySelector('.js-page-data-json')!.textContent!)
                        : null
                    : null,
            );
        };

        document.addEventListener('new-page-ready', onNewPageReady);

        return () => {
            document.removeEventListener('new-page-ready', onNewPageReady);
        };
    }, [setPageData]);

    /**
     * Блокирование скролла страницы
     */
    useEffect(() => {
        if (openedPopups.length > 0 || scrollLocked) {
            lockBodyScroll();
        } else {
            unlockBodyScroll();
        }
    }, [scrollLocked, openedPopups.length]);

    useEffect(() => {
        const setDevice = () => {
            switch (true) {
                case matchMedia('(max-width: 767px)').matches:
                    setMediaQueryDeviceState('vertical-mobile');
                    break;
                case matchMedia('(max-width: 900px) and (orientation: landscape)').matches:
                    setMediaQueryDeviceState('horizontal-mobile');
                    break;
                case matchMedia('(min-width: 768px) and (max-width: 1199px) and (orientation: portrait)').matches:
                    setMediaQueryDeviceState('vertical-tablet');
                    break;
                case matchMedia('(min-width: 768px) and (max-width: 1199px) and (orientation: landscape)').matches:
                    setMediaQueryDeviceState('horizontal-tablet');
                    break;
                default:
                    setMediaQueryDeviceState('desktop');
                    break;
            }
        };

        const onResize = () => {
            setDevice();
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', setDevice);
    }, [setMediaQueryDeviceState]);

    return null;
};

export default AppInits;
