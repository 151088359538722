import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { getQuickSearch, getSearch, useQuickSearch } from '@/shared/api/search';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import { DICT } from '@/shared/config/dict';
import { useLocale } from '@/shared/hooks/use-locale';
import { encodeUrl } from '@/shared/lib/utils/filter/chpu/encode-url';
import { validationMessages } from '@/shared/lib/utils/validation/messages';
import Button from '../../shared/Button';
import CloseCrossButton from '../../shared/CloseCrossButton/CloseCrossButton';
import { SEARCH_POPUP_NAME } from './SearchPopup';

export default function SearchPopupContent({
    name,
    query,
    setQuery,
    quickSearchResults,
}: {
    name: string;
    query: string;
    setQuery: (query: string) => void;
    quickSearchResults: string[];
}) {
    const locale = useLocale();
    const router = useRouter();
    const inputRef = useRef<HTMLInputElement>(null);

    const trimmedQuery = query.trim();
    const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
    const searchAllowed = trimmedQuery.length > 0;
    const placeholder = DICT.SEARCH_INPUT_TEXT[locale];
    const { closePopup } = useSetOpenedPopupsState();

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (searchAllowed) {
            router.push(
                encodeUrl(
                    '/search/',
                    { q: trimmedQuery },
                    {
                        ignoredKeys: ['limit'],
                    },
                ),
            );
            setQuery('');
            setValidationErrorMessage('');
            closePopup(SEARCH_POPUP_NAME);
        } else {
            setValidationErrorMessage(validationMessages[locale].EMPTY_FIELD);
        }
    };

    const handleQuickSearchItemClick = (item: string) => {
        const result = item.replace(/<\/?[^>]+(>|$)/g, '');
        setQuery(result);
    };

    return (
        <div className="search-popup-content">
            <CloseCrossButton handleClick={() => closePopup(name)} />

            <div className="search-popup-form-wrapper">
                <form noValidate onSubmit={onSubmit} className="search-popup-form">
                    <label className="visually-hidden">{placeholder}</label>
                    <input
                        ref={inputRef}
                        type="text"
                        className="search-popup-form__input"
                        placeholder={placeholder}
                        onChange={(event) => {
                            setQuery(event.target.value);
                            setValidationErrorMessage('');
                        }}
                        onBlur={() => {
                            setValidationErrorMessage('');
                        }}
                        value={query}
                        required
                    />
                    <div className="search-popup-form__btns">
                        {trimmedQuery.length > 0 && <CloseCrossButton type="button" handleClick={() => setQuery('')} />}

                        <Button type="submit" variant="outline-dark" className="search-popup-form__btn">
                            {DICT.SEARCH_FIND[locale]}
                        </Button>
                    </div>
                </form>
                <div className={classNames('search-popup-form__message', { 'is-visible': !!validationErrorMessage })}>
                    {validationErrorMessage}
                </div>

                {quickSearchResults && quickSearchResults.length > 0 && (
                    <div className="search-popup-form__quick-search">
                        {quickSearchResults.map((result, i) => (
                            <button
                                key={i}
                                className="search-popup-form__quick-search-item text-s"
                                dangerouslySetInnerHTML={{ __html: result }}
                                onClick={() => handleQuickSearchItemClick(result)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
