/**
 * Трансформация объекта в FormData
 */
export function toFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    formData.append(`${key}[]`, item);
                });
            } else if (value instanceof File) {
                formData.append(key, value);
            } else {
                formData.append(key, String(value));
            }
        }
    }

    return formData;
}
