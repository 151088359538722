import { useState } from 'react';
import { useQuickSearch } from '@/shared/api/search';
import { useDebounce } from '@/shared/hooks/use-debounce';
import Popup from '../../shared/Popup';
import SearchPopupContent from './SearchPopupContent';

export const SEARCH_POPUP_NAME = 'search-popup';

export default function SearchPopup() {
    const [query, setQuery] = useState<string>('');
    const debouncedQuery = useDebounce(query, 500);

    const { data } = useQuickSearch(
        {
            params: { q: debouncedQuery || '' },
        },
        { data: [] },
    );

    return (
        <Popup name={SEARCH_POPUP_NAME} data-popup-preset="slide-right" className="search-popup" overlay={true}>
            <SearchPopupContent
                name={SEARCH_POPUP_NAME}
                query={query}
                setQuery={setQuery}
                quickSearchResults={data?.data.words || []}
            />
        </Popup>
    );
}
