import { create } from 'zustand';

export type PageDataState = {
    pageId: number;
} | null;

export const usePageDataStore = create<{
    pageData: PageDataState;
    setPageData: (data: PageDataState) => void;
}>((set) => ({
    pageData: null,
    setPageData: (data) => set(() => ({ pageData: data })),
}));

export const usePageDataValue = () => usePageDataStore((state) => state.pageData);
export const useSetPageDataState = () => usePageDataStore((state) => state.setPageData);
