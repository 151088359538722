import { create } from 'zustand';
import { addUniqueItemToArray } from '@/shared/lib/utils/arrays/add-unique-item-to-array';

interface OpenedPopupsState {
    openedPopups: string[];
    actions: {
        setOpenedPopups: (openedPopups: string[]) => void;
        openPopup: (name: string) => void;
        closePopup: (name: string) => void;
    };
}

const useOpenedPopupsStore = create<OpenedPopupsState>((set) => ({
    openedPopups: [],
    actions: {
        setOpenedPopups: (openedPopups: string[]) => set({ openedPopups }),
        openPopup: (name: string) =>
            set((state) => ({
                openedPopups: addUniqueItemToArray(state.openedPopups, name),
            })),
        closePopup: (name: string) =>
            set((state) => ({
                openedPopups: state.openedPopups.filter((popupName) => popupName !== name),
            })),
    },
}));

export const useOpenedPopupsValue = () => useOpenedPopupsStore((state) => state.openedPopups);
export const useSetOpenedPopupsState = () => useOpenedPopupsStore((state) => state.actions);
