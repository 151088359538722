import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { ApiMeta } from '@/shared/api';

interface Props {
    meta: ApiMeta &
        Partial<{
            baseTitle: string;
            ogImage: string;
        }>;
}

const AppHead = ({ meta }: Props) => {
    const pathname = usePathname();
    const title = [meta.title, meta.baseTitle].filter(Boolean).join(' — ');

    return (
        <Head>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={meta.description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            {meta.keywords && <meta name="keywords" content={meta.keywords} />}
            {process.env.NEXT_PUBLIC_HOST && (
                <meta property="og:url" content={process.env.NEXT_PUBLIC_HOST + (pathname === '/' ? '' : pathname)} />
            )}
            <meta property="og:locale" content="ru" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.og_title || title} />
            <meta property="og:description" content={meta.og_description || meta.description} />
            <meta property="og:image" content={meta.ogImage} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:image" content={meta.ogImage} />
            <meta name="twitter:title" content={meta.tw_title || title} />
            <meta name="twitter:description" content={meta.tw_description || meta.description} />

            <link rel="canonical" href={`${(process.env.NEXT_PUBLIC_HOST || '').replace(/\/$/, '')}${pathname}`} />

            <link rel="apple-touch-icon" sizes="180x180" href="/img/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon/favicon-16x16.png" />
            <link rel="shortcut icon" href="/img/favicon/favicon.ico" />

            <link rel="preload" href="/fonts/BrownLLPan-Light.otf" as="font" type="font/otf" crossOrigin="anonymous" />
            <link
                rel="preload"
                href="/fonts/BrownLLPan-Regular.otf"
                as="font"
                type="font/otf"
                crossOrigin="anonymous"
            />
            <link rel="preload" href="/fonts/BrownLLPan-Medium.otf" as="font" type="font/otf" crossOrigin="anonymous" />
        </Head>
    );
};

export default AppHead;
