import { create } from 'zustand';

export const useFullLogoStore = create<{
    fullLogo: boolean;
    setFullLogo: (value: boolean) => void;
}>((set) => ({
    fullLogo: false,
    setFullLogo: (value) => set({ fullLogo: value }),
}));

export const useFullLogoStateValue = () => useFullLogoStore((state) => state.fullLogo);
export const useSetFullLogoState = () => useFullLogoStore((state) => state.setFullLogo);
