import Axios from 'axios';

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const apiBaseUrl = `${process.env.NEXT_PUBLIC_API_HOST || ''}/api`;

export const axiosAPI = Axios.create({
    baseURL: apiBaseUrl,
    headers: { 'Cache-Control': 'no-cache' },
});
