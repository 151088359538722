import classNames from 'classnames';
import {
    CSSProperties,
    ChangeEventHandler,
    FocusEventHandler,
    InputHTMLAttributes,
    ReactNode,
    forwardRef,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useResizeObserver } from 'usehooks-ts';
import { mergeRefs } from '@/shared/lib/utils/merge-refs';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
    /**
     * Иконка
     */
    icon?: ReactNode;
    /**
     * Лейбл (описание)
     */
    label: string;
    /**
     * Нужно ли спрятать лейбл
     */
    hideLabel?: boolean;
    /**
     * Сообщение
     */
    message?: string;
    /**
     * Валиден ли инпут
     */
    valid?: boolean;
    /**
     * Рендерить ли сообщение
     */
    showMessage?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
    ({ id, label, hideLabel = false, valid = true, message, showMessage = true, icon, ...props }, ref) => {
        const inputEl = useRef<HTMLInputElement>(null);
        const labelEl = useRef<HTMLLabelElement>(null);
        const [focused, setFocused] = useState(false);
        const [innerValue, setInnerValue] = useState(props.value || props.defaultValue);
        const { height: labelElHeight } = useResizeObserver({ ref: labelEl });
        const isFloatedLabelActive = focused || (typeof innerValue === 'string' ? innerValue.length > 0 : false);

        const onFocus: FocusEventHandler<HTMLInputElement> = () => {
            setFocused(true);
        };

        const onBlur: FocusEventHandler<HTMLInputElement> = () => {
            setFocused(false);
        };

        const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
            setInnerValue(event.target.value);
        };

        useEffect(() => {
            const input = inputEl.current;

            const onInputBlur = (event: Event) => {
                if (event.target instanceof HTMLInputElement) {
                    setInnerValue(event.target.value);
                    setFocused(document.activeElement === inputEl.current);
                }
            };

            input?.addEventListener('blur', onInputBlur);

            return () => {
                input?.removeEventListener('blur', onInputBlur);
            };
        }, []);

        return (
            <div style={{ '--label-height': `${labelElHeight}px` } as CSSProperties}>
                <input
                    {...props}
                    ref={mergeRefs([ref, inputEl])}
                    id={id}
                    data-testid="input"
                    className={classNames('form-control floated-labels', props.className, {
                        'floated-labels--active': isFloatedLabelActive,
                        'is-error': !valid,
                    })}
                    onFocus={(event) => {
                        onFocus(event);
                        props.onFocus?.(event);
                    }}
                    onBlur={(event) => {
                        onBlur(event);
                        props.onBlur?.(event);
                    }}
                    onChange={(event) => {
                        onChange(event);
                        props.onChange?.(event);
                    }}
                />
                <label
                    ref={labelEl}
                    htmlFor={id}
                    className={classNames('form-label text-s', { 'visually-hidden': hideLabel })}
                >
                    {label}
                </label>
                {icon && <div className="input-group__icon">{icon}</div>}
                {showMessage && (
                    <div className="app-message text-xs" aria-live="polite" aria-describedby={id}>
                        {message || ''}
                    </div>
                )}
            </div>
        );
    },
);

Input.displayName = 'Input';

export default Input;
