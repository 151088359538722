import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { DEFAULT_LOCALE } from '../hooks/use-locale';
import { axiosAPI } from './axios-instance';
import { AppLocale } from './lib/types';

export type SearchResultItem = {
    category: 'posts' | 'programs' | 'questions';
    content: string;
    day_id: number;
    description: string;
    id: number;
    score: number;
    slug: string;
    theme: string;
    url: {
        type: 'event' | 'page' | 'category';
        slug: string;
    };
    name?: string;
    question?: string;
    answer?: string;
    item_title?: string;
    item_desc?: string;
    item_category?: string;
    item_url?: string;
};

export type ApiSearchRequest = Partial<{
    q: string | null;
    category: ('posts' | 'programs' | 'questions') | null;
    limit: number;
    page: number;
}>;

export type ApiSearchResponse = {
    data: {
        categories: {
            slug: string;
            name: string;
            count: number;
        }[];
        elements: SearchResultItem[];
        totalCount: number;
    };
};

export type ApiQuickSearchRequest = {
    q: string;
};

export type ApiQuickSearchResponse = {
    data: string[];
};

type Params = {
    params?: ApiSearchRequest;
    locale?: AppLocale;
    signal?: AbortSignal;
};

type ParamsQuickSearch = {
    params?: ApiQuickSearchRequest;
    signal?: AbortSignal;
};

export type AppSearchResponse = {
    data: {
        categories: {
            slug: string;
            name: string;
            count: number;
        }[];
        elements: SearchResultItem[];
        totalCount: number;
    };
};

export type AppQuickSearchResponse = {
    data: string[];
};

function transformApiResponse(response: ApiSearchResponse): AppSearchResponse {
    const { data } = response;

    if (!Array.isArray(data.elements)) {
        data.elements = Object.values(data.elements);
    }

    const pageSlugToLinkMap: { [key: string]: string } = {
        main: '/',
        partners: '/participation/partners/',
        visitors: '/participation/visitors/',
        participants: '/participation/participants/',
        registration: '/registration/',
        program: '/program/',
        expo: '/expo/',
        technological: '/events/technological/',
        hr: 'events/summit/',
        hotels: '/services/hotels/',
        shuttles: '/services/shuttles/',
        'car-rental': '/services/car-rental/',
        'car-accreditation': '/servicescar-accreditation/',
        'food-areas': '/services/food-areas/',
        contacts: '/contacts/',
    };

    data.elements.forEach((item) => {
        let link;
        if (item.url?.type && item.url.type === 'page') {
            link = pageSlugToLinkMap[item.url.slug as keyof typeof pageSlugToLinkMap] || '/';
        }

        if (item.category === 'posts') {
            item.item_title = item.name;
            item.item_desc = item.description;
            item.item_category = 'Новости';
            item.item_url = `/news/${item.url.slug}/${item.slug}/`;
        } else if (item.category === 'programs') {
            item.item_title = item.theme;
            item.item_desc = item.description;
            item.item_category = 'Программы';
            if (item.url.type === 'page') {
                item.item_url = `/program/`;
            } else if (item.url.type === 'event') {
                item.item_url = `/community/${item.url.slug}/`;
            }
        } else if (item.category === 'questions') {
            item.item_title = item.question;
            item.item_desc = item.answer;
            item.item_category = 'Частые вопросы';
            if (item.url.type === 'page') {
                item.item_url = `${link}#faq`;
            }
            //  else if (item.url.type === 'event') {
            //     item.item_url = `/events/${item.url.slug}`;
            // }
        }
    });

    if (typeof data.totalCount === 'undefined') {
        data.totalCount = data.categories.reduce((sum, category) => sum + category.count, 0);
    }

    return response;
}

export const getSearch = ({ params = {}, signal, locale = DEFAULT_LOCALE }: Params): Promise<AppSearchResponse> =>
    axiosAPI.get<AppSearchResponse>('/v1/search', { params, signal, headers: { 'App-Locale': locale } }).then((res) => {
        return transformApiResponse(res.data);
    });

export const useSearchQuery = (data: Params, enabled = true, initialData?: AppSearchResponse) =>
    useQuery({
        queryKey: ['search', data.params],
        queryFn: ({ signal }) => getSearch({ ...data, signal }),
        initialData: initialData,
        enabled,
    });

export const getQuickSearch = ({ params, signal }: ParamsQuickSearch) =>
    axiosAPI.get('/v1/search/autocomplete', { params, signal }).then((res) => res.data);

export const useQuickSearch = (data: ParamsQuickSearch, initialData?: AppQuickSearchResponse) =>
    useQuery({
        queryKey: ['autocomplete', data.params?.q],
        queryFn: ({ signal }) => getQuickSearch({ ...data, signal }),
        initialData,
        placeholderData: keepPreviousData,
        enabled: !!data.params?.q,
    });
