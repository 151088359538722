import ArrSVG from '@public/icons/btn-arr.svg';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { FocusEventHandler, useRef, useState } from 'react';
import { object, string } from 'yup';
import { postSubscribe } from '@/shared/api/subscribe';
import { FeedbackResponse } from '@/shared/lib/types';
import Input from '../Input';

function FooterInput({ data = {} }) {
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [response, setResponse] = useState<FeedbackResponse | null>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const schema = object({
        email: string()
            .required('Поле обязательно к заполнению')
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Введите корректный адрес эл. почты'),
    });

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        setFieldTouched,
        setValues,
        setTouched,
        initialTouched,
        initialValues,
    } = useFormik({
        initialValues: {
            ...data,
            email: '',
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            clearTimeout(timeoutRef.current);
            setResponse(null);

            try {
                const data = await postSubscribe(values);

                setResponse({
                    status: data.status,
                    message: data.data?.message || 'Успешно отправлено',
                });

                if (data.status === 'success') {
                    setValues(initialValues);
                    setTouched(initialTouched);
                } else {
                    setResponse({ status: 'error', message: data.data?.message || 'Что-то пошло не так' });
                }
            } catch (err) {
                setResponse({ status: 'error', message: 'Что-то пошло не так' });
                throw err;
            } finally {
                timeoutRef.current = setTimeout(() => {
                    setResponse(null);
                }, 5000);
            }
        },
    });

    const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
        setFieldTouched(event.target.name, false);
    };

    return (
        <form ref={formRef} className="footer-input-wrapper" noValidate onSubmit={handleSubmit}>
            <Input
                type="email"
                name="email"
                className="footer-input"
                placeholder={'Введите свой e-mail'}
                required
                label=""
                disabled={isSubmitting}
                id="feedback-form-email"
                value={values.email}
                onInput={handleChange}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={handleBlur}
                message={touched.email ? errors.email : ''}
                valid={!(!!touched.email && !!errors.email)}
            />
            <button
                type="submit"
                className={classNames('footer-input-btn', { 'is-submitting': isSubmitting })}
                disabled={isSubmitting}
                aria-label="Отправить"
            >
                <ArrSVG />
            </button>
            {response?.message && (
                <div
                    className={classNames('footer-input-response text-xs', { 'is-error': response.status === 'error' })}
                >
                    {response.message}
                </div>
            )}
        </form>
    );
}

export default FooterInput;
