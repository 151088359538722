import classNames from 'classnames';
import { AnimatePresence, m as motion } from 'framer-motion';
import { HTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAppReadyStateValue } from '@/shared/atoms/app-ready';
import Button from '@/shared/components/shared/Button/Button';
import { DICT } from '@/shared/config/dict';
import { useLocale } from '@/shared/hooks/use-locale';
import { useTypograf } from '@/shared/hooks/use-typograf';

interface Props extends HTMLAttributes<HTMLElement> {}

const COOKIE_NAME = 'COOKIES_USAGE_ACCEPTED';

const CookiesAgreement = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const locale = useLocale();
    const [cookies, setCookies] = useCookies([COOKIE_NAME]);

    const [shouldTransition, setShouldTransition] = useState(false);
    const tpText = useTypograf(DICT.COOKIE_TEXT[locale]);
    const appReady = useAppReadyStateValue();

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (appReady) {
            timer = setTimeout(() => {
                setShouldTransition(true);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [appReady]);

    return (
        <AnimatePresence>
            {shouldTransition && cookies[COOKIE_NAME] !== true && (
                <motion.div
                    {...(props as any)}
                    ref={ref}
                    className={classNames('cookies-agreement', props.className)}
                    initial={{ opacity: 0, x: 10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 10 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                >
                    <div className="text-title cookies-agreement__text text-s">{tpText}</div>
                    <Button
                        variant="outline-light"
                        className="cookies-agreement__btn outline-primary"
                        onClick={() =>
                            setCookies(COOKIE_NAME, true, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })
                        }
                    >
                        Согласен
                    </Button>
                </motion.div>
            )}
        </AnimatePresence>
    );
});

CookiesAgreement.displayName = 'CookiesAgreement';

export default CookiesAgreement;
