import { create } from 'zustand';

type State = {
    appReady: boolean;
};

type Action = {
    setAppReady: (state: State['appReady']) => void;
};

export const useAppReadyStore = create<State & Action>((set) => ({
    appReady: true,
    setAppReady: (newState) => set(() => ({ appReady: newState })),
}));

export const useAppReadyStateValue = () => useAppReadyStore((state) => state.appReady);
export const useSetAppReadyState = () => useAppReadyStore((state) => state.setAppReady);
