import { RefObject, useEffect } from 'react';

export function useOnOutsideClickAction(ref: RefObject<Element> | RefObject<Element>[], fn: () => void) {
    useEffect(() => {
        const closeOnOutsideClick = (event: Event) => {
            const target = event.target as HTMLElement;

            if (Array.isArray(ref)) {
                if (
                    ref.every((r) => {
                        const parent = r.current;

                        if (parent) {
                            const parentSelector = parent.className
                                .split(' ')
                                .map((className) => `.${className}`)
                                .join();
                            const closest = target.closest(parentSelector);

                            return !closest || (closest && closest !== parent);
                        }
                    })
                ) {
                    fn();
                }
            } else {
                const parent = ref.current;

                if (parent) {
                    const parentSelector = parent.className
                        .split(' ')
                        .map((className) => `.${className}`)
                        .join();
                    const closest = target.closest(parentSelector);

                    if (!closest || (closest && closest !== ref.current)) {
                        fn();
                    }
                }
            }
        };

        document.documentElement.addEventListener('click', closeOnOutsideClick);

        return () => document.documentElement.removeEventListener('click', closeOnOutsideClick);
    }, [fn, ref]);
}
