export enum PAGES {
    MAIN = 1,
    PARTICIPANTS = 3,
    PARTNERS = 4,
    VISITORS = 5,
    EXPO = 7,
    TECHNOLOGICAL = 8,
    HR = 9,
    PROGRAM = 10,
    REGISTRATION = 14,
    HOTELS = 16,
    SERVICES = 13,
    SHUTTLES = 19,
    CAR_RENTAL = 17,
    CAR_ACCREDITATION = 18,
    FOOD_AREAS = 20,
    KONTAKTY = 15,
    PAVILION = 2,
}

export const COMMUNITY_EVENTS_LIMIT = 4;

export const MONTH_DICT = {
    1: 'января',
    2: 'февраля',
    3: 'марта',
    4: 'апреля',
    5: 'мая',
    6: 'июня',
    7: 'июля',
    8: 'августа',
    9: 'сентября',
    10: 'октября',
    11: 'ноября',
    12: 'декабря',
} as const;

export const SEARCH_RESULT_PAGE_LIMIT = 20;

export const STORAGE_SCROLL_POSITION = 'scrollPosition';
