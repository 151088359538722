import CloseSVG from '@public/icons/close-btn.svg';
import React from 'react';
import { DICT } from '@/shared/config/dict';
import { useLocale } from '@/shared/hooks/use-locale';

type CloseCrossButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    handleClick: () => void;
};

export default function CloseCrossButton({
    handleClick,
    type = 'button',
    className = 'menu-popup-close-btn',
    ...rest
}: CloseCrossButtonProps) {
    const locale = useLocale();

    return (
        <button type={type} className={className} aria-label={DICT.CLOSE[locale]} onClick={handleClick} {...rest}>
            <CloseSVG />
        </button>
    );
}
