import ArrSVG from '@public/icons/header-arr.svg';
import Link from '@/shared/components/shared/Link';
import Dropdown from '../Dropdown';

interface Props {
    links: { name: string; slug: string }[];
    btnText: string;
}

const HeaderDropdown = ({ btnText, links }: Props) => {
    return (
        <Dropdown className={'header-btn middle-out'}>
            <Dropdown.Toggler className="header-btn__toggler">
                <span className="text-xs">{btnText}</span>
                <ArrSVG className="header-arrow-svg" />
            </Dropdown.Toggler>
            <Dropdown.Content className="header-btn__content" position="bottom-right">
                <ul className="list-unstyled menu-dropdown-list">
                    {links.map((link, i) => (
                        <li key={i}>
                            <Dropdown.Picker tag="div" className="dropdown-list__item">
                                <Link className="menu-dropdown-link text-xs" href={link.slug}>
                                    {link.name}
                                </Link>
                            </Dropdown.Picker>
                        </li>
                    ))}
                </ul>
            </Dropdown.Content>
        </Dropdown>
    );
};

export default HeaderDropdown;
