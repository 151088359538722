export function clearMessages(form: Element) {
    const messageElements = Array.from(form.querySelectorAll('.app-message'));
    messageElements.forEach((messageElement) => {
        messageElement.textContent = '';
    });
}

export function getLang() {
    return document.documentElement.lang.toLowerCase();
}

/**
 * Проверка ссылки, заканчивается ли она на .pdf, учитывающая добавление версионирования файлов
 */
export const isPdf = (url: string) => {
    const pdfPattern = /\.pdf(\?.*)?$/;
    return pdfPattern.test(url);
};

/**
 * Проверка ссылки, заканчивается ли она на .zip, учитывающая добавление версионирования файлов
 */
export const isArchive = (url: string) => {
    const archivePattern = /\.(zip|rar|7z|tar|gz|tgz|bz2|xz|zst|iso)(\?.*)?$/i;
    return archivePattern.test(url);
};
